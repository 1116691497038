@use "../abstracts" as *;
@use "../layout/calendar" as *;
@use "../layout/spacing" as *;
@use "../layout/container" as *;
@use "../theme/input" as *;
@use "../theme/borders" as *;
@use "../theme/colors" as *;

$datepicker__font-family: $font-body;
$datepicker__font-size: $calendar-font-size;
$datepicker__selected-color: $calendar-date-background;
$datepicker__border-color: $white;
$datepicker__border-radius: rem(16);
$datepicker__text-color: $gray-200;
$datepicker__background-color: transparent;

.date-picker {
    input {
        padding: 0px $input-padding-x $input-padding-x;
        border: none;
        background-color: transparent;
        font-weight: $input-font-weight;
        cursor: pointer;
        width: 100%;

        &:focus-visible {
            outline: 0;
        }
    }
}

.popper-container {
    min-width: 100%;
}

.modal {
    .popper-container {
        &[data-placement^="bottom"] {
            transform: translate(
                0px,
                48px
            ) !important; // popper container not aligned with input field when it is inside modals
        }
        // flipped
        &[data-placement^="top"] {
            transform: translate(
                0px,
                -48px
            ) !important; // popper container not aligned with input field when it is inside modals
        }
    }
}

$pad-y: rem(24);
$pad-x: rem(24);
$pad-bottom: rem(16);

.react-datepicker__header {
    border-bottom: none !important;
}

.calendar-container {
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    width: 100%;

    &.react-datepicker--time-only {
        overflow: hidden;
    }

    .react-datepicker {
        &__navigation {
            top: calc($pad-y - rem(4));
            background-color: #efefef;
            border-radius: $calendar-date-border-radius;
            width: 36px;
            height: 36px;

            &--previous {
                left: $pad-x;
            }

            &--next {
                right: $pad-x;
            }

            &-icon {
                top: 4px;

                &--next,
                &--previous {
                    left: 0px;
                }

                &::before {
                    border-color: $body-color;
                }
            }
        }
        &__header {
            padding: $pad-y $pad-x;
            font-weight: $fw-semi-bold;

            &--time {
                padding-bottom: rem(12);
            }
        }

        &-time__header {
            border-bottom: border();
            text-align: left;
            padding-bottom: rem(8);
        }

        &__current-month,
        &-time__header {
            font-weight: inherit;
            font-size: rem(20);
        }

        &__current-month {
            padding-bottom: $pad-bottom;
        }

        &__month {
            margin: 0px $pad-x $pad-bottom;
            background: $datepicker__background-color;

            &-container {
                float: none;
            }
        }

        &__week {
            + .react-datepicker__week {
                margin-top: rem(5);
            }
        }
        &__day {
            font-weight: $calendar-date-font-weight;
            padding: rem(8);
            width: 42px;
            height: 42px;

            &:not(&--disabled):not(&--selected):not(&--in-range) {
                &:hover,
                &:focus {
                    background-color: $calendar-date-opac-color;
                }
            }

            &-names {
                font-weight: $calendar-date-font-weight;
                display: flex;
                justify-content: space-between;
                font-size: $small;
            }

            // to not highlight day in all months
            &--keyboard-selected:not(.react-datepicker__day--in-range) {
                background: none;
            }

            &--selected,
            &:hover,
            &:focus {
                border-radius: $calendar-date-border-radius;
            }

            &--selected,
            &--in-range {
                color: $white !important;
                border-radius: $calendar-date-border-radius;
            }

            &:not(&--disabled) {
                color: $body-color;
            }

            &--disabled {
                &.react-datepicker__day--in-range {
                    &:hover,
                    &:focus {
                        background-color: $datepicker__selected-color;
                    }
                }
            }
        }

        &__time-container {
            width: 100%;

            .react-datepicker__time {
                .react-datepicker__time-box {
                    width: 100%;
                    margin: 0px;
                    padding: 0px $pad-x;
                    text-align: left;

                    ul.react-datepicker__time-list {
                        @include remove-scrollbar();
                        li.react-datepicker__time-list-item {
                            padding: rem(10) rem(12);
                            border-radius: $br-xs;
                            height: 40px;
                            margin-bottom: rem(8);
                            font-weight: $calendar-date-font-weight;

                            &--selected,
                            &:hover:not(&--disabled) {
                                background-color: $gray-400;
                                color: $body-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "react-datepicker/src/stylesheets/datepicker.scss";
