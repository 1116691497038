@use "../theme/borders" as *;

// dashboard
%img-background {
    background-repeat: no-repeat;
    background-position: center;
}

%img-psuedo-icon {
    content: "";
    display: inline-block;
    @extend %img-background !optional;
}

.img--round {
    @extend %img-psuedo-icon !optional;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    --opac: 0.15;

    // package dashboard
    &-sm {
        width: 32px;
        height: 32px;
    }
}

.img--square {
    @extend %img-psuedo-icon !optional;
    border-radius: $border-radius-sm;
    width: 88px;
    height: 88px;
    --opac: 0.1;
}
