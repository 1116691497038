@use '../../scss/abstracts' as *;
@use '../../scss/layout/typography' as *;
@use '../../scss/layout/spacing' as *;
@use '../../scss/theme/colors' as *;
@use '../../scss/theme/table' as *;

.order-summary-card {
	.card-body {
		display: flex;
		flex-direction: column;

		.card-body-subtitle--flex {
			order: -1;
			margin-bottom: rem(10);
		}

		.btn-toggle {
			&::after {
				align-self: start;
			}
		}

		.transaction {
			&-desc {
				display: flex;
				flex-direction: column;
				gap: rem(6);
				@extend .small;
				@extend .small-bold;
			}
		}
	}
}
