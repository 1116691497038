@use "../abstracts" as *;
@use "./container" as *;
@use "./typography" as *;
@use "./spacing" as *;
@use "./buttons" as *;
@use "../theme/modal" as *;
@use "../theme/colors" as *;

.modal {
    line-height: 1.5;

    &-title {
        &-sm {
            font-size: $body-2;
        }
    }

    // when modals are on top of each other
    &-opac {
        opacity: 0.3;
    }

    &-content {
        background-color: $white;
    }

    &-body-heading {
        margin-bottom: $text-spacer-sm;

        &-title {
            @extend .headline-4 !optional;
        }

        p {
            margin-top: $text-spacer-sm;
        }

        // seat map - move seats modal
        &-sm {
            margin-bottom: $text-spacer-xs;
        }

        // seat map - reset modal
        &-lg {
            margin-bottom: rem(40);
        }

        // ticket breakdown modal -> create offer
        &--flex {
            @extend %flex-between !optional;

            > * {
                margin: 0px;
            }
        }
    }

    .header {
        // contact attendees -> preview modal
        &--close {
            padding: 0px $modal-inner-padding;
            padding-top: em(15);
        }
    }

    // whole screen - add events to package
    &-full {
        .modal-dialog {
            max-width: 96%;
        }
    }

    // used for contact attendees -> preview msg modal, bank information modal - NOT USING, seat map -> select map modal, add events to package
    &-dialog-scrollable {
        $margin: $modal-inner-padding;

        // create offer modal, create role modal
        .modal-header {
            padding: $modal-inner-padding 0px;
            margin-right: $margin;
            margin-left: $margin;
            margin-bottom: $text-spacer-xs;
        }

        // seat map -> select map modal, add events to package
        .header {
            padding: $modal-inner-padding;
            padding-bottom: 0px;
            margin-bottom: rem(13);

            .modal-title {
                margin-bottom: $text-spacer-sm;
            }

            .heading {
                font-weight: $fw-semi-bold;
            }

            // add events to package
            .header--flex {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: $text-spacer-sm;

                .modal-title {
                    margin-bottom: 0px;
                }
            }
        }

        .modal-body {
            display: flex;
            flex-direction: column;
            padding-top: rem(3) !important;
            @include remove-scrollbar();
        }

        .btn-group-flex {
            padding: $modal-inner-padding;
            padding-top: 0px;
        }
    }

    // used for ticket breakdown, role, team, bank information modals - NOT USING
    &--with-margin {
        $margin: $modal-inner-padding;

        .modal {
            &-header {
                padding: $modal-inner-padding 0px;
                margin-right: $margin;
                margin-left: $margin;

                .modal-title {
                    font-size: $body-1;
                }
            }

            &-body {
                display: flex;
                flex-direction: column;
            }
        }
    }

    // used for delete, refund, contact attendees and check in confirmations, qr code modal, seat map - reset/save modals, mark as paid -> fan profile
    &--lg {
        $padding: rem(32);

        .modal {
            &-body {
                padding: $padding;
            }
        }
    }

    .btn-group-flex {
        gap: $gap;
        margin-top: $text-spacer-sm;

        &-stretch {
            > .btn {
                flex: 1;
            }
        }

        &.vstack {
            flex: 0;

            > .btn {
                width: 100%;
            }
        }
    }
}

// MAY REMOVE
#qr-modal {
    @include breakpoint-min(md) {
        max-width: 448px;
    }
}

.modal {
    // create availability period -> create offers
    &-800w {
        max-width: 800px;
    }

    // reset modal -> seat map
    &-1000w {
        max-width: 1000px;
    }

    // seat map - select map
    .h {
        &-sm {
            height: 500px;
        }

        // event settings - upload attraction modal
        &-md {
            min-height: 525px;
        }

        // assign to fan modal, add event modal -> create package
        &-lg {
            min-height: 724px;
        }
    }
}
