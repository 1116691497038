@use "../../../../scss/abstracts" as *;
@use "../../../../scss/layout/typography" as *;
@use "../../../../scss/layout/spacing" as *;
@use "../../../../scss/layout/container" as *;

.num-seats {
    // pricing level/holds - default
    font-weight: $fw-medium;

    // move modal
    &-lg {
        font-weight: $fw-semi-bold;
    }
}

.cef-seatmap-wrapper {
    position: absolute;
    overflow: hidden;
    top: $banner-height;
    left: $sidebar-width-lg;
    right: 0;
    bottom: 0;
    min-width: $min-width;

    &.panel-open {
        left: calc($sidebar-width-lg + $sidebar-width-sm);
    }
}

.unassigned::before,
.open::before {
    @extend %dot;
}

.sub-item {
    &-name-container {
        margin-left: calc($dot-width + $gap-sm);
    }
}
