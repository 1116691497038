@use "../../scss/abstracts" as *;
@use "../../scss/layout/spacing" as *;
@use "../../scss/layout/typography" as *;
@use "../../scss/layout/modal" as *;

#custom-reports {
    .card {
        &-body {
            padding-left: 0px;
            padding-right: 0px;
            margin-left: rem(24);
            margin-right: rem(24);

            .split-row {
                margin-bottom: rem(19);
            }
            &-title {
                margin: 0px;
            }

            &-title,
            .heading {
                font-size: $body-2;
            }

            .card-section {
                @extend .seperator;

                &--with-flex-header {
                    padding-top: $text-spacer-xs;
                }
            }

            .select-label {
                font-weight: $fw-medium;
            }

            .form-select {
                width: 233px;
            }
        }
    }

    #events-card {
        &.card--open {
            height: 429.78px;
        }
        .collapse {
            height: calc(100% - 94px);
        }
    }

    #event-list {
        overflow: auto;
        max-height: 195px;
        padding-top: rem(2);

        ul {
            @extend .d-flex-column;
            gap: $gap;
        }
    }
}

#column-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
        flex: 0 0 18%;
    }
}

#transaction-type-col {
    border-left: border();
    padding-left: $text-spacer;
    @extend .d-flex-column;
}

#results-per-page-container {
    position: absolute;
    right: 0;
}

#custom-report-modal {
    @extend .modal--with-margin;
    width: 420px;
}
