@use "../abstracts" as *;
@use "./spacing" as *;
@use "./typography" as *;
@use "./container" as *;
@use "../theme/card" as *;
@use "../theme/borders" as *;
@use "../theme/colors" as *;

.card {
    &--light {
        background-color: #ffff;
    }

    &--dark {
        background-color: $gray-300;
    }

    &-title {
        @extend .normal !optional;
        @extend .normal-bold !optional;
        margin-bottom: 0px;

        // create offer -> inventory, access code cards
        // attractions -> event settings
        // edit base price -> seat map
        &-xs {
            font-size: $small;
        }

        // seat map - choose map
        // dashboard, package dashboard
        &-lg {
            font-size: $body-1;
        }

        // offer cards -> create offer
        &-thin {
            font-weight: $fw-medium;
        }

        // package dashboard
        &-upper {
            @extend .text-upper !optional;
        }
    }

    // seat map - select map
    // create offer cards
    &-subtitle {
        @extend .subtitle !optional;
        margin-top: $text-spacer-xs;
    }

    // seat map - select map cards
    // seat map -> seat card (panel), seat map -> move seats modal (revenue card), offer create/edit modal cards
    // event info -> publish modal
    // add member modal -> event settings
    // contact attendees -> preview message
    &-with-border {
        border: border();
    }

    // create event cards and seat map - select map
    // create offer -> discreet links
    &--md {
        .card-body {
            padding: rem(24);
        }
    }

    .btn-link,
    .btn-toggle {
        font-family: $font-body;
    }

    // multiple refunds page, create event cards, seat map -> seat card (panel), seat map -> move seats modal (revenue card), access code, ticket limits cards, package dashboard
    &--sm {
        .card-body {
            padding: rem(16);
            font-weight: $fw-medium;

            .caption-label {
                color: $text-muted;
                font-size: $caption;
                padding-bottom: rem(3);
            }
        }
    }

    // warning cards, cards -> create offer
    &--xs {
        .card-body {
            padding: rem(12);
        }
    }

    // event settings, seat map - select map, offers
    .card-body-heading {
        margin-bottom: $text-spacer;

        // create offer, reports, package dashboard
        &--sm {
            margin-bottom: $text-spacer-sm;
        }

        // offers, package dashboard
        &--xs {
            margin-bottom: $text-spacer-xs;
        }

        // seat map -> select map
        &--lg {
            margin-bottom: rem(40);
        }

        // create event flow -> event settings, event details, create offer, reports
        &--flex {
            @extend .heading--flex !optional;
            @extend .flex-sm !optional;

            // seat map -> sidebar -> panel -> draggable seats
            // create offer -> availability
            &-space-between {
                @extend .heading--flex-space-between !optional;
            }
        }
    }

    // event settings - attraction cards
    &-thumbnail {
        background: $gray-300;
        border: border();
        border-radius: $border-radius-sm;
        width: 207px;
        height: 148px;

        .card-body {
            padding: rem(12);

            .card-img-top {
                height: 92px;
                border-radius: $br-xs;
            }

            .card-title {
                @extend .card-title-xs !optional;
                font-weight: $fw-medium;
                margin-top: rem(12);
            }
        }
    }

    // dashboard
    .card-body-title {
        font-size: $body-1;

        &--flex {
            display: flex;
            align-items: center;
            gap: $gap;
        }
    }
}
