@use '../../../scss/abstracts' as *;
@use '../../../scss/layout/forms' as *;
@use '../../../scss/layout/typography' as *;
@use '../../../scss/layout/container' as *;
@use '../../../scss/layout/spacing' as *;
@use '../../../scss/theme/borders' as *;

.btn-card {
	flex-basis: 207px;
	font-family: $font-body;
	font-weight: $fw-medium;
	height: 148px;
	border-radius: $border-radius-sm;

	&.error {
		@extend .error-border;
	}

	.btn-container {
		@extend .d-flex-column;
		gap: $gap;
		color: #3e8bf7;

		&::before {
			content: url($data-svg-prefix + map-get($icons, 'plus'));
			filter: brightness(0) saturate(100%) invert(48%) sepia(27%) saturate(3334%) hue-rotate(196deg)
				brightness(98%) contrast(98%);
			margin: auto;
			@extend %icon-size-md;
			transform: scale(1.7); // 40px
		}
	}
}
