@use "../../../scss/abstracts" as *;
@use "../../../scss/theme/colors" as *;

#package-list {
    .package {
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 24px;
        position: relative;

        > * {
            color: $white;
        }

        .btn-more {
            position: absolute;
            top: 32px;
            right: 32px;

            #dropdown-item-manage {
                order: -1;
            }

            svg {
                filter: $white-filter;
            }

            &:hover,
            &:focus {
                background-color: rgba($gray-300, 0.2);
            }
        }
    }
}
