@use "../abstracts" as *;
@use "./container" as *;
@use "./spacing" as *;
@use "./typography" as *;
@use "./buttons" as *;
@use "../theme/input" as *;
@use "../theme/buttons" as *;
@use "../theme/colors" as *;
@use "../theme/borders" as theme;

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    color: $body-color;
    -webkit-text-fill-color: $body-color;
    transition: background-color 5000s ease-in-out 0s;
}

$form-control-height: 47.59px;

.form-control-height {
    height: $form-control-height;
}

.form-group {
    & + .form-group {
        margin-top: $text-spacer;
    }
}

// event settings
.form-check--with-desc {
    & + .form-group {
        margin-top: $text-spacer-xs;
    }
}

// event settings, -> create event flow
.form-check-display + .form-check-display,
.form-check--with-desc + .form-check--with-desc {
    margin-top: $text-spacer-sm;
}

// event details - general on-sale modal
// create period - create offer -> availability
.row + .form-check,
.hstack + .form-check {
    margin-top: $text-spacer-sm;
}

.form-control,
.form-select {
    &:disabled {
        opacity: 0.5;
    }
}

.form-floating {
    label {
        font-size: $caption;
    }
}

.error {
    color: $danger;
}

.error-border {
    border: $input-border-width solid;
    border-color: $danger;

    &--with-radius {
        border-radius: theme.$border-radius;
    }
}

// event details, event settings
legend {
    @extend .normal !optional;
    @extend .normal-bold !optional;
    margin-bottom: 0px;
    padding-bottom: rem(14);

    // event details
    &.sm {
        padding-bottom: rem(10);
    }

    // event settings
    &.lg {
        padding-bottom: $text-spacer;
    }
}

.form-label--flex,
.form-label {
    margin-bottom: rem(12);
}

.form-label {
    @extend .text-upper !optional;
    @extend .text-upper-caption !optional;
    padding: 0px;
    color: $body-color;

    // create offer -> ticket limits, pricing and fees form label
    &-sm {
        &.form-label {
            @extend .small !optional;
            @extend .small-bold !optional;
        }
    }

    // create offer -> ticket limits, pricing and fees form labels
    &--lowercase {
        margin-bottom: 0px;
        text-transform: capitalize;
    }
}

.form-check-input {
    cursor: pointer;
}

// create offer -> ticket limits
.form-switch {
    .form-check {
        &-label {
            @extend .small-label !optional;
        }

        &-input {
            height: rem(26);
        }
    }
}

// select ga seats modal -> seat map
// ticket limits and facility fee -> create offer
.form-control,
.input-group {
    &-sm {
        height: rem(28);
    }
}

// create event flow -> event details, event settings, create offer
.required-text {
    @extend .error !optional;
    @extend %icon-text-box !optional;
    > * {
        @extend .small !optional;
        @extend .small-bold !optional;
    }

    &::after {
        content: url($data-svg-prefix + map-get($icons, "warning"));
        @extend %icon-size-md !optional;
        filter: $danger-filter;
    }
}

.form-text {
    display: block;
}

// create offer -> ticket limits form switches
.hstack .form-switch {
    margin: 0px;
}

$pad-x: rem(35);

.form-check:not(.form-switch) {
    min-height: 0px;
    $margin-offset-x: rem(-35);

    padding-left: $pad-x;

    & {
        .form-check-input {
            margin-left: $margin-offset-x;
        }
    }
}

.form-check {
    &-input {
        &:checked[type="radio"] {
            background-color: transparent;
            background-size: rem(25);
        }
    }

    // event settings -> create event flow
    &-error {
        .form-check-input {
            @extend .error-border !optional;
        }
    }

    // event settings, create offer -> create event flow
    &--with-desc {
        .form-check {
            margin-bottom: 0px;
        }

        .subtitle {
            padding-left: $pad-x;
        }
    }

    &-inline {
        margin-bottom: 0px;
    }

    &-label {
        @extend .small-label !optional;
    }

    &-display {
        display: flex;
        align-items: center;
        gap: $gap-md;
        flex: 1;

        &--lg {
            gap: 16px;
        }

        .form-check {
            &-label {
                margin-bottom: rem(4);
                @extend .normal !optional;
                @extend .normal-bold !optional;
            }

            &-text {
                display: block;
                color: $text-muted;
                font-weight: $fw-medium;
            }
        }
    }
}

.date-picker-card {
    display: flex;
    gap: $gap-sm;
    border-radius: theme.$border-radius;
    border: $input-border-width solid $border-color;
    width: 100%;
    cursor: pointer;
    position: relative;
    padding-left: rem(12);

    .date-picker input {
        padding-left: 0px;
    }

    &::before {
        padding-top: rem(15);
        @extend .icon-button-lg !optional;
        flex-shrink: 0;
    }

    // without label
    &-sm {
        &::before {
            padding-top: rem(11);
        }

        .date-picker input {
            padding-top: rem(12);
            padding-bottom: rem(12);
        }
    }

    &.day-picker-card {
        &::before {
            content: url($data-svg-prefix + map-get($icons, "calendar"));
        }
    }

    &.time-picker-card {
        &::before {
            content: url($data-svg-prefix + map-get($icons, "clock"));
        }
    }

    &:focus-within {
        border-color: $input-focus-border-color;
    }

    &.error {
        @extend .error-border !optional;
    }

    .form-label {
        margin: 0px;
        text-transform: capitalize;
        position: relative;
        padding-top: $input-padding-y;
        font-weight: $fw-medium;
        color: #6f767e;
    }
}

// used in contact attendees and daily ticket counts form
.tag {
    background-color: #3e8bf7;
    border-radius: 7px;
    padding: rem(4) rem(8);
    align-items: center;

    &-label {
        line-height: 1.7;
        color: $white;
        @extend .caption !optional;
        @extend .caption-bold !optional;
        margin-right: rem(8);
    }

    &-remove-btn {
        background-color: $white;
        color: $clr-primary-dark;
        border: none;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        appearance: none;
        @extend .caption !optional;
        @extend .caption-bold !optional;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        line-height: 0;
        padding: 0px rem(4);

        &:hover {
            background-color: #000000bc;
            color: $white;
        }
    }
}

// search field
@mixin input-icon-box(
    $radius: theme.$border-radius,
    $color: $clr-neutral-6,
    $bg: $white,
    $padding-x: $input-padding-x
) {
    align-items: center;
    border-radius: $radius;
    border: $input-border-width solid $color;
    padding-left: $padding-x;
    background-color: $bg;

    &:focus-within {
        border-color: $input-focus-border-color;
    }

    .input-group {
        border: none;

        &-error {
            @extend .error-border !optional;
        }
    }

    .form-control {
        background-color: transparent;
        border: none;
        border-radius: 0;
        min-height: auto;
    }

    .form-control:focus {
        background-color: transparent;
    }
}

// password, tracking link url field -> tracking links page
.input-wrapper {
    display: flex;
    @include input-icon-box($bg: transparent);
    padding-right: $input-padding-x;
    padding-left: 0px;

    &.input-wrapper-error {
        @extend .error-border !optional;
    }
}
