@use '../abstracts' as *;
@use './borders' as *;

$modal-backdrop-bg: #262626;
$modal-backdrop-opacity: 0.9;
$modal-inner-padding: rem(24);
$modal-content-border-radius: $border-radius;
$modal-header-padding-x: $modal-inner-padding;
$modal-header-padding-y: $modal-header-padding-x;

// width
$modal-sm: 420px;
$modal-lg: 580px;
$modal-xl: 700px;
