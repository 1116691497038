@use "../abstracts" as *;
@use "../theme/colors" as *;
@use "../theme/borders" as *;

.btn-link-disabled {
    pointer-events: none;
    opacity: 0.65;

    &--with-cursor {
        cursor: not-allowed !important;
        pointer-events: all;
    }
}

.link-goTo {
    display: flex;
    align-items: center;
    gap: 8px;

    &::after {
        content: url($data-svg-prefix + map-get($icons, "rightArrow"));
        filter: $primary-dark-filter;

        line-height: 0px;
    }
}

@mixin hover-effect($border-radius: $border-radius, $background-color: $gray-300, $outline: none) {
    background-color: $background-color;
    border-radius: $border-radius;
    outline: $outline;
}

// used in sidenav on dashboard/settings and create offers modal
$nav-link-padding-y: rem(12);
$nav-link-padding-x: rem(16);
