@use "../../../scss/theme/colors" as *;

.specialty-offer {
    margin-left: 31px;

    .offer-row {
        &::before {
            right: 46%;
        }
        .col {
            margin-left: -12px;
        }
    }
}

.offer-row {
    position: relative;

    &::before {
        content: "";
        height: 24px;
        width: 1px;
        background-color: $gray-200;
        position: absolute;
        right: 44.5%;
        top: 50%;
        transform: translateY(-50%);
    }

    .heading {
        white-space: normal;
    }
}
