@use '../../../../../../scss/abstracts' as *;
@use '../../../../../../scss/layout/spacing' as *;
@use '../../../../../../scss/theme/input' as *;
@use '../../../../../../scss/theme/borders' as *;
@use '../../../../../../scss/theme/colors' as *;

#legend {
    &-container {
        width: 155px;
        border: border();
        // padding: $input-padding-y $input-padding-x;
        background: $white;
        border-radius: $border-radius;
        display: flex;
        flex-direction: column;
        font-size: $small;
        position: absolute;
        left: 20px;
        bottom: 20px;
        overflow: hidden;
        .btn {
            line-height: 24px;
            color: #000000;
        }
    }

    &-list {
        display: flex;
        flex-direction: column;
        gap: $gap-sm;
        padding-top: 8px;
        padding-bottom: 8px;
    
        // padding-right: $text-spacer-xs;

        .list-item {
            display: flex;
            gap: $gap-xs;
            align-items: center;
            line-height: 24px;
            background: $white;
            padding-top: 0;
            padding-bottom: 0;

            &::before {
                content: '';
                width: 12px;
                height: 12px;
                border-radius: $br-xs;
            }

            &.sold {
                &::before {
                    background-color: #45B26B;
                }
            }

            &.selectable {
                &::before {
                    background-color: #FFA500;
                }
            }

            &.selected {
                &::before {
                    background-color: #141416;
                }
            }

            &.unselected {
                &::before {
                    background-color: #c6c8cc;
                }
            }
        }
    }
}