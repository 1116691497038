@use "../../scss/abstracts" as *;
@use "../../scss/layout/typography" as *;

.empty {
    @extend .info-text;
    @extend .info-text-md;

    p {
        margin-bottom: 0px;
        font-weight: $fw-medium;
    }

    &.center {
        flex-direction: column;
        justify-content: center;

        p {
            text-align: center;
        }

        button {
            margin-top: rem(8);
        }
    }

    &.lg {
        @extend .info-text-lg;

        p {
            font-size: $body-2;
        }
    }

    .subtitle {
        font-size: $small !important;
        font-weight: $fw-normal;
        margin-top: 0px;
    }
}
