@use ".././../../scss/abstracts" as *;
@use ".././../../scss/layout/typography" as *;
@use ".././../../scss/layout/images" as *;
@use ".././../../scss/theme/borders" as *;
@use ".././../../scss/theme/colors" as *;

#events-list {
    .btn-container {
        background-color: $white;
        border-radius: $border-radius-sm;
        padding: rem(24);

        .btn {
            background-color: $white;
            border-radius: 16px;
            padding: rem(24);
            border: border($color: $primary, $border-style: dashed);

            &::before {
                content: "";
                background-image: url("../../../assets/icons/add.svg");
                @extend .background-primary;
                @extend %img-background;
                width: 64px;
                height: 64px;
                border-radius: $border-radius;
            }
        }
    }
}

.events-container {
    background-color: $gray-300;
    padding: rem(16);
    border-radius: $border-radius;
}

.events-list {
    gap: 12px;
}
