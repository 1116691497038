@use '../../scss/abstracts' as *;
@use '../../scss/layout/container' as *;
@use '../../scss/theme/colors' as *;

#page-loading-container {
	position: absolute;
	bottom: 0;
	left: $sidebar-width;
	right: 0;
	height: calc(100vh - $navbar-height);

	&.without-sidebar {
		left: 0;
	}

	// default page loading spinner
	&.xl {
		#spinner-icon {
			width: 48px;
			height: 48px;
		}
	}

	// configure map -> seat map
	&.sm {
		#spinner-icon {
			width: inherit;
			height: inherit;
		}
	}
}
