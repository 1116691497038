@use "../../../../../scss/abstracts" as *;
@use "../../../../../scss/layout" as *;
@use "../../../../../scss/theme/colors" as *;

/*
 * Sidebar
 */

$margin-offset-x: rem(-9);
$padding-x: rem(8);

%pseudo-border {
    content: "";
    position: absolute;
    bottom: -0.5px;
    left: $padding-x;
    right: $padding-x;
    height: 1px;
    background-color: $border-color;
}

.sidebar {
    .sidebar-header {
        padding-bottom: rem(16);
        padding-top: rem(12);
        border-bottom: border();
    }

    .heading {
        @extend .normal !optional;
        @extend .normal-bold !optional;
    }

    .btn-add {
        background-color: rgba($primary, 0.1);
        border: border($color: $primary);
        margin-top: $text-spacer-xs;

        &:hover,
        &:focus {
            &::before {
                @extend .btn-icon--white;
            }
        }
    }

    .btn-unassigned {
        border-radius: 0px;
        margin: 0px $margin-offset-x;
        padding: $padding-x;
        position: relative;

        > *:not(.text-link) {
            color: $text-muted;
        }

        &:hover,
        &:focus {
            &::after {
                display: none;
            }
        }

        &::after {
            @extend %pseudo-border;
        }
    }

    .list-container {
        border-bottom: border();
        @extend .d-flex-column;
    }

    .scaling-container {
        border-bottom: none;

        .droppable {
            position: relative;
            padding-top: rem(17);
            padding-bottom: rem(17);

            .list-item--hover {
                &:hover,
                &:focus {
                    &::after {
                        display: none;
                    }
                }
            }
            &::after {
                @extend %pseudo-border;
                z-index: -1;
            }

            .split-row + .split-row {
                margin-top: rem(11);
            }
        }
    }

    .inventory-container {
        .droppable {
            padding-top: rem(8);
            padding-bottom: rem(8);
        }
    }

    .unassigned {
        &::before {
            content: "";
        }
    }

    // unassigned button on scaling menu
    .unassigned {
        &::before {
            background-color: $light;
        }
    }

    .droppable {
        padding-right: $padding-x;
        padding-left: $padding-x;
        margin-right: $margin-offset-x;
        margin-left: $margin-offset-x;

        // .flex {
        //     height: rem(20);
        // }
    }
}

.financial-info-container {
    background: $white;
    border-top: border();
    margin-top: auto;
    position: sticky;
    bottom: 0;

    .financial-info {
        background: inherit;
        margin-right: $margin-offset-x;
        margin-left: $margin-offset-x;
        padding: 0px $padding-x rem(20);
    }
}
