@use '../abstracts' as *;
@use './buttons' as *;
@use './typography' as *;
@use '../theme/colors' as *;
@use '../theme/borders' as *;
@use '../theme/dropdown' as *;
@use '../theme/buttons' as *;

// org dropdown and event status dropdown
.dropdown {
	&-toggle {
		border-radius: $border-radius;
		font-family: $font-body;
		font-weight: $fw-medium;
	}

	&-menu {
		&.w {
			// org dropdown -> navigation
			&-lg {
				min-width: 272px;
			}

			// event status dropdown -> event banner
			&-xl {
				min-width: 364px;
			}
		}

		// org and event status dropdowns
		&-lg {
			padding: rem(16);
			background-color: #FFFF;
			border-top: 0;
			border-top-right-radius: 0;
			border-top-left-radius: 0;
			box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05),
				0px 32px 48px -8px rgba(0, 0, 0, 0.1);
		}
	}

	&-item {
		border-radius: $border-radius-sm;
		@extend .small !optional;
		@extend .small-bold !optional;

		& + .dropdown-item {
			margin-top: rem(10);
		}

		&:active,
		&.active {
			background-color: $dropdown-link-hover-bg;
			color: $body-color;
		}
	}
}

.btn-more {
	&:hover,
	&:focus,
	&.show {
		background-color: $gray-300;
		border-radius: $br-xs;
	}
}
