

.vjx-loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 200;
    background-color: #f4f5f6;
    animation: shimmer 1s ease infinite;
    @keyframes shimmer {
        0% {
            background-color: #f4f5f6;
        }
        50% {
            background-color: rgb(237, 239, 243);
        }
        100% {
            background-color: #f4f5f6;
        }
    }
    h4 {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;
        color: #23262F;
        margin-bottom: 0;
    }   
}