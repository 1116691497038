@use "../../../../scss/abstracts" as *;
@use "../../../../scss/layout/container" as *;
@use "../../../../scss/layout/spacing" as *;
@use "../../../../scss/theme/colors" as *;

.tabs {
    .tab {
        height: 100%;
        display: flex;
        position: relative;

        &.active {
            &::after {
                content: "";
                position: absolute;
                bottom: -$banner-padding-y;
                left: 0;
                right: 0;
                height: 2px;
                background-color: $primary;
            }
        }
    }
}

#saved {
    position: absolute;
    right: calc(100% + 16px);
    @extend %icon-text-box;
    gap: $gap-sm;

    &::before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.5906 6.07611C15.916 6.40155 15.916 6.92918 15.5906 7.25462L8.92389 13.9213C8.59845 14.2467 8.07082 14.2467 7.74538 13.9213L4.41205 10.588C4.08661 10.2625 4.08661 9.73488 4.41205 9.40944C4.73748 9.08401 5.26512 9.08401 5.59056 9.40944L8.33464 12.1535L14.412 6.07611C14.7375 5.75067 15.2651 5.75067 15.5906 6.07611Z' fill='%23777E91'/%3E%3C/svg%3E");
    }
}
