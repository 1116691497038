@use './colors' as *;

$br-primary: 12px; // inputs
$br-secondary: 90px; // buttons
$br-md: 10px;
$br-sm: 8px;
$br-xs: 4px;
$border-width: 1px;
$border-color: $clr-neutral-6;

@function border($border-width: $border-width, $color: $border-color, $border-style: solid) {
	@return $border-width $border-style $color;
}

@mixin full-width-border($border-width: $border-width, $color: $border-color) {
	content: '';
	height: $border-width;
	position: absolute;
	left: 0;
	right: 0;
	background-color: $border-color;
	z-index: 1;
}

%rounded {
	border-radius: $br-md;
}

.br-xs {
	border-radius: $br-xs;
}

.event-image-lg {
	border-radius: $br-primary;
}

.event-image {
	@extend %rounded;
}

.seperator {
	border-top: border();
}
