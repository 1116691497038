@use "../abstracts" as *;
@use "../theme/colors" as *;
@use "../theme/borders" as *;

// Page Spacers
$spacer-md: rem(80);

// Text Spacers
$heading-spacer: rem(32); // page headings
$heading-spacer-sm: rem(28); // section headings
$gap: rem(16);
$gap-md: rem(12);
$gap-sm: rem(10);
$gap-xs: rem(4);

$text-spacer: rem(32);
$text-spacer-sm: rem(24);
$text-spacer-xs: rem(16);

.spacer-md {
    padding: $spacer-md 0px;
}

.event-form,
.package-form {
    padding-bottom: rem(50);
}

// create event forms, settings - teams
section + form,
form .card + .card,
section + section {
    margin-top: $text-spacer;
}

%flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.row + .row {
    margin-top: rem(11);
}

.split-row {
    @extend %flex-between !optional;
    font-size: $small;

    > * {
        margin-bottom: 0px;
    }

    // move seats modal - seat map
    &-lg {
        font-size: $body-2;
    }
}

// seat map - scaling and inventory
// heading--flex, actions-group-flex
.flex {
    display: flex;
    align-items: center;
    gap: $gap;

    // create offer -> inventory
    // form label flexbox, card body heading flexbox
    &-sm {
        gap: $gap-sm;
    }

    // card body flex, empty container center gap
    &-lg {
        gap: rem(24);
    }
}

.heading--flex-space-between,
.section-heading--flex {
    @extend %flex-between !optional;

    > * {
        margin-bottom: 0px;
    }
}

.heading--flex,
.form-label--flex {
    & > *,
    .btn {
        margin: 0px !important;
    }
}

.heading--flex,
.actions-group-flex {
    @extend .flex !optional;

    .form-floating,
    .btn-download {
        flex-basis: 240px;
        height: 64px;

        .form-select {
            height: 100%;
        }
    }

    .btn-download {
        flex-basis: 135px;
    }

    .search {
        flex: 1;
        height: 64px;
    }
}

.form-label--flex {
    @extend .flex !optional;
    @extend .flex-sm !optional;

    legend {
        width: max-content;
    }
}

// MAY NOT NEED
.stat-row {
    display: flex;
    align-items: center;
    gap: $gap-xs;
    @extend .caption !optional;
    @extend .caption-bold !optional;
    color: $text-muted;
}

// ticket breakdown -> create offer
// availability periods -> create availability period modal
.list {
    padding-left: 0px;
    padding-right: 0px;

    & + .list:not(.list.list-group-item) {
        margin-top: $text-spacer-sm;
    }

    // ticket breakdown -> create offer
    &-sm {
        & + .list-sm:not(.list.list-group-item) {
            margin-top: rem(8);
        }
    }

    &-with-seperator {
        & + .list-with-seperator:not(.list.list-group-item) {
            @extend .seperator;
        }
    }
}

// move seats modal - seat map
// ticket breakdown -> create offer
// payouts
// event status dropdown - event banner
// package dashboard
.seperator {
    padding-top: $text-spacer-sm;
    margin-top: $text-spacer-sm;

    &-sm {
        padding-top: $text-spacer-xs;
        margin-top: $text-spacer-xs;
    }

    &-xs {
        padding-top: rem(8);
        margin-top: rem(8);
    }
}

// event details -> create event flow
$list-item-sm-padding-x: rem(11);
$margin-offset: -$list-item-sm-padding-x;

// availability periods - create offer -> create event flow
$list-item-xs-padding-x: rem(7);
$margin-offset-sm: -$list-item-xs-padding-x;

// event details, availability periods - create offer -> create event flow
// to offset the hover state
.offset-container {
    margin: $margin-offset;

    // availability periods -> create offer
    &-sm {
        margin: $margin-offset-sm;
    }
}

// event details, availability periods -> create offer, roles, team members, offers, ticket breakdown -> create offer, seat map - select map, event -> create package, assign to fan modal
.list-item {
    display: flex;
    padding: rem(12);
    background-color: $gray-300;
    position: relative;

    &--light {
        background-color: $white;
    }

    &--primary {
        background-color: rgba($primary, 0.12) !important;
        border: border($color: $primary);
    }

    // ticket breakdown -> create offer, seat map - select map
    &--vertical {
        flex-direction: column;
    }

    // assign to fan modal
    &--with-seperator {
        .vstack {
            min-width: 0px;
            flex-basis: 0px;
        }
        &::before {
            content: "";
            width: $border-width;
            height: calc(100% - (12px * 2));
            position: absolute;
            left: 50%;
            right: 50%;
            top: 50%;
            background-color: $gray-200;
            transform: translateY(-50%);
        }
    }

    // availability periods -> create offer
    &--without-bg {
        background-color: transparent;
    }

    // availability periods -> create offer
    &-xs {
        padding: rem(8) $list-item-xs-padding-x rem(5);
    }

    // event details -> event details page
    &-sm {
        padding: rem(10) $list-item-sm-padding-x;
    }

    // ticket breakdown -> offers, seat map - select map
    // availability periods - create period modal -> offers
    &-lg {
        border-radius: $border-radius-sm;
        padding: $text-spacer-xs;

        // assign to fan modal
        &--sm {
            padding: rem(12);
        }
    }
}

#bank-card,
#tax-card {
    gap: rem(24);

    @include breakpoint-min(md) {
        gap: $gap-sm;
    }

    .info {
        h1 {
            margin-bottom: $text-spacer-xs;
        }
    }
}
