@use '../abstracts' as *;
@use './forms' as *;
@use '../theme/input' as *;
@use '../theme/borders' as theme;

.input-group {
	border: $input-border-width solid theme.$border-color;
	border-radius: theme.$border-radius;

	&:focus-within {
		border-color: $input-focus-border-color;
	}

	&-text {
		border: none;
		border-radius: $br-md;
		padding: $input-padding-y;

		&.transparent {
			background-color: transparent;
		}
	}

	.form-control {
		border: none;
		background-color: transparent;

		&:focus {
			border-color: transparent;
		}
	}

	&.input-group-error {
		@extend .error-border !optional;
	}

	&-sm {
		width: rem(104);

		.input-group-text,
		.form-control {
			height: 100%;
			min-height: auto;
			padding: $input-padding-y-sm $input-padding-x-sm;
		}
	}
}
