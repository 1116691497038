@use "../../../scss/abstracts" as *;
@use "../../../scss/layout/typography" as *;
@use "../../../scss/layout/spacing" as *;
@use "../../../scss/layout/images" as *;
@use "../../../scss/theme/colors" as *;

.cards {
    .card {
        height: 100%;

        &-body {
            padding-top: rem(24);
            padding-bottom: rem(24);
        }

        .card-body {
            &-heading {
                margin-bottom: $text-spacer-xs;
            }

            &-title {
                &-lg {
                    @include breakpoint-min(lg) {
                        font-size: $h3-font-size;
                    }
                }
            }
        }

        [class^="col-"] {
            @include breakpoint-max(lg) {
                + [class^="col-"] {
                    margin-top: $text-spacer-sm;
                }
            }
        }

        &#tickets-sold-card {
            .amount {
                font-size: $body-1;
            }
        }

        .amount {
            @extend .normal;
            @extend .normal-bold;
        }

        .small-text {
            font-size: $small;
            color: $text-muted;
            font-weight: $fw-semi-bold;
        }

        .page-views,
        .payout {
            .small-label--flex {
                margin: rem(12) 0px rem(7);
            }
        }

        .tickets-sold,
        .total-sales,
        .royalties,
        .net-sales,
        .page-views,
        .payout,
        .shareable-link,
        .qrcode {
            &::before {
                @extend .img--round;
            }
        }

        .tickets-sold {
            &:before {
                background-image: url("../../../assets/icons/ticket.svg");
                background-color: rgba($primary, var(--opac));
            }
        }

        .total-sales {
            &:before {
                background-image: url("../../../assets/icons/chart.svg");
                background-color: rgba($secondary, var(--opac));
            }
        }

        .royalties {
            &:before {
                background-image: url("../../../assets/icons/dollar.svg");
                background-color: rgba($clr-tertiary, var(--opac));
            }
        }

        .net-sales {
            &:before {
                background-image: url("../../../assets/icons/growth.svg");
                background-color: rgba($primary, var(--opac));
            }
        }

        .page-views {
            &:before {
                background-image: url("../../../assets/icons/eye.svg");
                background-color: rgba($body-color, var(--opac));
            }
        }

        .payout {
            &:before {
                background-image: url("../../../assets/icons/coin.svg");
                background-color: rgba($success, var(--opac));
            }
        }

        .shareable-link {
            .card-body-title {
                margin-top: rem(5);
            }
            &::before {
                background-image: url("../../../assets/icons/link.svg");
                background-size: 35px;
                background-position: left;
                width: 32px;
                height: 32px;
            }

            .btn {
                height: 42px;
            }
        }

        .qrcode {
            &:before {
                background-image: url("../../../assets/icons/qrcode.svg");
                background-position: left;
                width: 35px;
                height: 35px;
            }
        }
    }
}
