@use "../abstracts" as *;
@use "./container" as *;
@use "./spacing" as *;
@use "./typography" as *;
@use "../theme/card" as *;
@use "../theme/table" as *;
@use "../theme/colors" as *;
@use "../theme/borders" as *;

// used in order summary ticket row and custom reports order table
.table-container {
    border-radius: $border-radius;
    padding: rem(25) rem(34);
    background-color: $white;

    // custom reports orders table
    &--full-width {
        width: calc(100vw - calc($pad + rem(3)));
        max-width: 1440px;

        .table-container--fixed-height {
            max-height: 469px;
            overflow: auto;

            thead {
                background-color: $white;
                position: sticky;
                top: 0;
            }
        }
    }
}

.table {
    margin-bottom: 0px;

    > *,
    th {
        font-size: $small;
        white-space: nowrap;
    }

    th {
        min-width: 120px;
        color: $muted;
    }

    &.table-lg {
        thead {
            th {
                min-width: 80px;
            }
        }

        th,
        td {
            padding-right: rem(32);
            padding-left: rem(32);
        }

        th:first-of-type,
        td:first-of-type {
            padding-left: 0px;
        }

        th:last-of-type,
        td:last-of-type {
            padding-right: 0px;
        }
    }

    &:not(.table--full-width) {
        table-layout: fixed;

        th {
            text-align: center;

            &:first-of-type {
                text-align: left;
            }
            &:last-of-type {
                text-align: right;
            }
        }

        td {
            text-align: center;

            &:first-of-type {
                text-align: left;
            }
            &:last-of-type {
                text-align: right;
            }
        }

        th,
        td {
            @extend %text-truncate !optional;
        }
    }

    tbody {
        border-top: none !important;

        tr:last-of-type {
            border-bottom-width: 0px;
            border-bottom-style: none;
            border-bottom-color: transparent;
        }
    }
}
