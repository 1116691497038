/* Sass Variables */

$clr-primary: #3ea9f7;
$clr-primary-dark: #3e8bf7;
$clr-secondary: #9757d7;
$clr-tertiary: #ffd166;
$clr-success: #45b36b;
$clr-danger: #ef466f;
$clr-neutral-2: #23262f;
$clr-neutral-3: #353945;
$clr-neutral-4: #777e90;
$clr-neutral-5: #b1b5c3;
$clr-lightGray: #f4f4f4;
$clr-neutral-6: #e6e8ec;
$clr-neutral-7: #f4f5f6;
$clr-white: #fcfcfd;

// icons
$dark-filter: brightness(0) saturate(100%) invert(11%) sepia(9%) saturate(1469%) hue-rotate(187deg) brightness(92%)
    contrast(89%);

$white-filter: brightness(0) saturate(100%) invert(99%) sepia(100%) saturate(2%) hue-rotate(127deg) brightness(109%)
    contrast(101%);

$primary-filter: brightness(0) saturate(100%) invert(60%) sepia(17%) saturate(7053%) hue-rotate(182deg) brightness(104%)
    contrast(94%);

// primary-darker: alerts, info icon links and warning card color
$primary-dark-filter: brightness(0) saturate(100%) invert(44%) sepia(95%) saturate(643%) hue-rotate(187deg)
    brightness(96%) contrast(103%);

// alerts, info icon links and warning card, badges
$secondary-filter: brightness(0) saturate(100%) invert(49%) sepia(98%) saturate(3574%) hue-rotate(243deg)
    brightness(90%) contrast(86%);

// alerts, info icon, badges
$success-filter: brightness(0) saturate(100%) invert(58%) sepia(34%) saturate(683%) hue-rotate(88deg) brightness(96%)
    contrast(91%);

// alerts, info icon, badges
$danger-filter: brightness(0) saturate(100%) invert(48%) sepia(98%) saturate(3772%) hue-rotate(321deg) brightness(98%)
    contrast(92%);
