@use "../../../scss/abstracts" as *;
@use "../../../scss/theme/borders" as *;

#dropzone {
    background-color: $clr-lightGray;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: rem(76);

    .btn-upload {
        box-shadow: 0px 12px 13px -6px rgba(0, 0, 0, 0.04);
    }
    &.dropzone {
        &-sm {
            padding: rem(32);
        }

        &-radius {
            border-radius: $border-radius;
        }
    }
}
