@use "../../../scss/abstracts" as *;
@use "../../../scss/layout/typography" as *;
@use "../../../scss/layout/spacing" as *;
@use "../../../scss/layout/images" as *;
@use "../../../scss/layout/container" as *;
@use "../../../scss/theme/colors" as *;
@use "../../../scss/theme/borders" as theme;
@use "../../../scss/theme/input" as *;

.cards {
    .card {
        height: 100%;

        &-body {
            padding-top: rem(24);
            padding-bottom: rem(24);
        }

        .card-body {
            &-heading {
                margin-bottom: $text-spacer-xs;
            }

            &-title {
                &-lg {
                    @include breakpoint-min(lg) {
                        font-size: $h3-font-size;
                    }
                }
            }
        }

        [class^="col-"] {
            @include breakpoint-max(lg) {
                + [class^="col-"] {
                    margin-top: $text-spacer-sm;
                }
            }
        }

        #overview-box {
            div {
                flex-basis: 0px;
            }

            &::before {
                content: "";
                width: $border-width;
                height: 100px;
                position: absolute;
                left: 50%;
                right: 50%;
                top: 33%;
                background-color: theme.$border-color;
                transform: translateY(-50%);
            }
        }

        .package-img {
            width: 1332px;
            height: 145px;
        }

        .total-sales,
        .sold,
        .assign,
        .package-holders {
            &::before {
                @extend .img--round;
            }
        }

        .assign,
        .package-holders {
            &::after {
                @extend .img--round;
                @extend .img--round-sm;
                margin-left: auto;
                border: border($color: $gray-200);
                background-image: url($data-svg-prefix + map-get($icons, "rightArrow"));
                filter: $dark-filter;
            }
        }

        .total-sales {
            &:before {
                background-image: url("../../../assets/icons/growth.svg");
                background-color: rgba($primary, var(--opac));
            }
        }

        .sold {
            &:before {
                background-image: url("../../../assets/icons/box.svg");
                background-color: rgba($primary, var(--opac));
                filter: $primary-filter;
            }
        }

        .assign {
            &:before {
                background-image: url("../../../assets/icons/payment.svg");
                background-color: $gray-300;
            }
        }

        .package-holders {
            &:before {
                background-image: url($data-svg-prefix + map-get($icons, "info"));
                background-color: $gray-300;
                background-size: rem(24);
            }
        }
        #visibility-card {
            border-radius: theme.$border-radius-sm;

            .form-check {
                margin-bottom: 0px;
                &-label {
                    @extend .normal;
                    @extend .normal-bold;
                    margin-left: rem(16);
                }
            }
            .subtitle {
                margin-left: calc($form-switch-width + rem(13) + rem(16));
            }
        }
    }
}
