@use '../../../scss/theme' as *;
@use '../../../scss/abstracts' as *;
@use '../../../scss/layout/forms' as *;
@use '../../../scss/layout/spacing' as *;

.input-tag-container {
	border: $input-border-width solid $input-border-color;
	border-radius: $input-border-radius;
	display: flex;
	flex-wrap: wrap;
	min-height: 48px;

	&:focus-within {
		border-color: $input-focus-border-color;
	}

	.input-tags-input-container {
		background: none;
		flex-grow: 1;
		padding: 0px;

		input {
			background: transparent;
			padding: rem(2);
			width: 100%;
			height: 100%;
			border: 0;
			&:focus {
				border-color: $input-focus-border-color !important;
			}
		}
	}

	.input-tags-input,
	input {
		&:focus-visible {
			border: 0;
			outline: 0;
			box-shadow: none;
		}
	}

	.input-tags {
		display: inline-flex;
		flex-wrap: wrap;
		gap: $gap-sm;
		margin: 0px;
		padding: rem(8) $input-padding-x;
		width: 100%;
	}

	.input-tag {
		@extend .tag;
		display: flex;
		align-self: center;
		list-style: none;

		&-label {
			@extend .tag-label;
		}

		button {
			@extend .tag-remove-btn;
		}
	}
}
