@use '../../scss/abstracts' as *;
@use '../../scss/layout/spacing' as *;
@use '../../scss/layout/links' as *;
@use '../../scss/theme/colors' as *;

/*
 * Sidenav
 */

.sidebar {
	.nav-link {
		display: flex;
		align-items: center;
		gap: $gap-md;
		padding: $nav-link-padding-y $nav-link-padding-x;
		font-family: $font-body;
		font-weight: $fw-medium;
		color: $body-color;
		line-height: 24px;
		text-wrap: nowrap;

		&:hover,
		&:focus,
		&.active {
			@include hover-effect();
			color: inherit;
		}
	}

	.nav-list {
		// dashboard button toggles only
		.btn-toggle {
			&:hover {
				@include hover-effect();
			}
		}

		// dashboard links only
		.nav-link {
			padding: $nav-link-padding-y;
		}

		+ .nav-list {
			margin-top: $text-spacer-xs;
			padding: $text-spacer-xs 0px;
			border-top: border();
		}
	}

	.submenu {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: $gap-xs;
		margin-top: rem(5);
		.nav-item {
			padding-left: rem(38);
		}

		&::before {
			content: '';
			position: absolute;
			margin-left: rem(26.6);
			width: 2px;
			height: 100%;
			background-color: #efefef;
		}
	}

	#settings-menu {
		.nav-link {
			&::after {
				line-height: 0;
				content: url($data-svg-prefix + map-get($icons, 'chevron'));
				margin-left: auto;
				vertical-align: middle;
			}
		}
	}
}
