@use '../../scss/abstracts' as *;
@use '../../scss/theme/colors' as *;
@use '../../scss/theme/input' as *;
@use '../../scss/theme/borders' as theme;

#refund-orders {
	&-header {
		.card--xs {
			flex: 0 0 16%;

			&.amount-card {
				flex-basis: 20%;
			}
		}
	}

	.table {
		thead {
			th {
				min-width: 180px;
			}
		}
	}
}
