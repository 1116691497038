@use "../abstracts" as *;
@use "./buttons" as *;
@use "./colors" as *;
@use "../layout/spacing" as *;

$input-font-size: $small;
$input-font-weight: $fw-medium;
$input-font-size-sm: $input-font-size;
$input-font-size-lg: $input-font-size;
$input-disabled-bg: transparent;
$input-bg: transparent;

// orders search input
$input-padding-y-lg: rem(19);
$input-padding-x-lg: rem(20);

// input
$input-padding-y: rem(12);
$input-padding-x: rem(16);

// create offer -> pricing and fees input group and ticket limits
$input-padding-x-sm: rem(8);
$input-padding-y-sm: 0px;

$input-border-width: 2px;
$input-focus-box-shadow: none;
$input-focus-border-color: $primary;
$input-placeholder-color: $gray-200;

$form-select-focus-box-shadow: none;
$form-select-bg-position: right 1em center;
$form-select-bg: $white;
$form-select-disabled-bg: $white;

$form-switch-color: $muted;
$form-switch-focus-color: $form-switch-color;
$form-switch-width: em(50);

$form-check-label-cursor: pointer;
$form-check-input-width: em(24);
$form-check-input-border: $input-border-width solid $gray-200;
$form-check-input-focus-border: $gray-200;
$form-check-input-focus-box-shadow: none;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$primary}'/></svg>");

$form-label-font-size: $small;
$form-check-margin-bottom: $text-spacer-xs;
$form-text-font-weight: $fw-medium;
$form-text-margin-top: rem(12);
