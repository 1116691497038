

.vjx-pm-heading {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: #000000;
    margin-bottom: 16px;
}

.vjx-pm-subheading {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #353945;
    margin-bottom: 0;
}

.vjx-pm-subheading-two {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #000000
}

.vjx-pm-paragraph {
    font-size: 14px;
    font-weight: 400;
    line-height: 23.8px;
    color: #353945;
}