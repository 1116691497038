@use '../../../scss/abstracts' as *;
@use '../../../scss/layout/container' as *;
@use '../../../scss/theme/colors' as *;


.publishingDisclaimer {
    position: absolute;
	bottom: 0;
	left: $sidebar-width;
	right: 0;
	height: calc(100vh - $navbar-height);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    // Text styling
    font-size: 16px;
    font-weight: 400;
    color: black;
}