@use "../abstracts" as *;
@use "./typography" as *;
@use "./container" as *;
@use "../theme/colors" as *;
@use "../theme/borders" as *;
@use "../theme/badge" as *;

// offers, event status (button) -> event banner
.badge {
    @extend %icon-text-box !optional;
    font-size: $badge-font-size;
    font-weight: $badge-font-weight;
    padding: $badge-padding-y $badge-padding-x;
    border-radius: $badge-border-radius;
    gap: rem(8);
    line-height: 1.7;
    @extend %icon-sm !optional;

    // event status -> event banner
    &.badge-lg {
        @extend .small !optional;
        @extend .small-bold !optional;
        padding-right: rem(16);
        padding-left: rem(16);
        border-radius: $border-radius;
    }

    &--draft {
        @extend .background-muted !optional;
        @extend %icon-edit !optional;
    }

    &--published,
    &--publishing {
        @extend .background-primary !optional;
        @extend %icon-success !optional;
    }

    &--scheduled {
        @extend .background-secondary !optional;
        @extend %icon-calendar !optional;
    }

    &--onsale {
        @extend .background-success !optional;
        @extend %icon-dot !optional;
    }

    &--ended {
        @extend .background-dark !optional;
        @extend %icon-clock !optional;
    }

    &--soldout {
        @extend .background-tertiary !optional;
        @extend %icon-tag !optional;
    }

    &--cancelled {
        @extend .background-danger !optional;
        @extend %icon-close !optional;
    }

    &--pending {
        @extend .background-secondary !optional;
    }

    &--paid {
        @extend .background-success !optional;
    }

    &--overdue {
        @extend .background-danger !optional;
    }

    &--plan {
        @extend .background-primary !optional;
    }

    // events list -> home wrapper
    &-label {
        text-transform: lowercase;
        color: $body-color;
        font-size: $small;
        font-weight: $fw-normal;
        border-radius: $border-radius-sm;
    }

    // dashboard, orders, team members
    &-outline {
        text-transform: uppercase;
        border-style: solid;
        border-width: 2px;
        background-color: transparent;

        &--primary {
            color: $primary;
            border-color: $primary;
        }

        &--secondary {
            color: $secondary;
            border-color: $secondary;
        }

        &--success {
            color: $success;
            border-color: $success;
        }

        &--dark {
            color: $body-color;
            border-color: $gray-200;
        }
    }
}
