

.vjx-admin-ga-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: none;
    outline: none;
    background-color: #FCFCFD;
    box-shadow: 0px 64px 64px -48px #1F2F461F;
    border-radius: 8px;
    padding: 24px;
    width: 100%;
    max-width: 580px;
    &::backdrop {
		background: rgba(0, 0, 0, 0.3);
	}
}