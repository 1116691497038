@use "../../../../../scss/abstracts" as *;
@use "../../../../../scss/layout/container" as *;
@use "../../../../../scss/layout/spacing" as *;
@use "../../../../../scss/theme/borders" as *;
@use "../../../../../scss/theme/colors" as *;
@use "../../../../../scss/theme/input" as *;

#map {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
    width: 100%;
    background-color: $gray-300;
    padding: em(24);
    overflow: hidden;
    transition: all 0.2s ease;

    &:not(.map--disabled) {
        cursor: pointer;
    }

    &.indicate-drag {
        filter: brightness(0.9);
    }

    .draggableContainer {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        &.active {
            opacity: 1;
        }

        .cursorIcon {
            border-radius: 50%;
            position: relative;
            background-color: rgba(62, 139, 247, 1);
            height: 60px;
            width: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid white;

            .dragHandle {
                position: absolute;
                z-index: 2;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                background-color: transparent;
                cursor: move;
            }

            .hotspotImage {
                width: 32.5px;
                height: 35px;
                margin-top: 4px;
                pointer-events: "none";
            }
        }

        .pulseAnimation {
            animation: pulse 2s infinite;
        }

        @keyframes pulse {
            0% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
            }

            70% {
                transform: scale(1);
                box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
            }

            100% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            }
        }
    }

    .seat {
        fill-rule: evenodd;
        fill: $gray-400;
        z-index: 0;
        cursor: pointer;
    }

    .controls-container {
        --right-btn-position: 20px;
        --left-btn-position: 10px;

        > * {
            position: absolute;
            bottom: 20px;

            &.sell-order-container {
                left: var(--left-btn-position);
            }

            &.zoomContainer {
                right: var(--right-btn-position);
            }
        }
    }
    .toolbar {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 0px;
        gap: $gap;
        position: absolute;
        width: 40px;
        height: 136px;
        right: 20px;
        bottom: 84px;
        background: rgba(252, 252, 253, 0.6);
        border: border($color: $white);
        border-radius: $br-md;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
        backdrop-filter: blur(6.5px);
        .toolbutton {
            border: none;
            background-color: transparent;
            outline: none;
            height: 24px;
            width: 40px;
            padding: 0;
            img {
                height: 24px;
                width: 24px;
            }
        }
    }

    &.indicate-drag {
        filter: brightness(0.9);
    }

    .draggableContainer {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;

        &.active {
            opacity: 1;
        }

        .cursorIcon {
            border-radius: 50%;
            position: relative;
            background-color: rgba(62, 139, 247, 1);
            height: 60px;
            width: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid white;

            .dragHandle {
                position: absolute;
                z-index: 2;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                background-color: transparent;
                cursor: move;
            }

            .hotspotImage {
                width: 32.5px;
                height: 35px;
                margin-top: 4px;
                pointer-events: "none";
            }
        }

        .pulseAnimation {
            animation: pulse 2s infinite;
        }

        @keyframes pulse {
            0% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
            }

            70% {
                transform: scale(1);
                box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
            }

            100% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            }
        }
    }

    .seat {
        fill-rule: evenodd;
        fill: $gray-400;
        z-index: 0;

        cursor: pointer;
    }

    .controls-container {
        --right-btn-position: 20px;
        --left-btn-position: 10px;

        > * {
            position: absolute;
            bottom: 20px;

            &.sell-order-container {
                left: var(--left-btn-position);
            }

            &.zoomContainer {
                right: var(--right-btn-position);
            }
        }
    }

    .toolbar {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 0px;
        gap: $gap;
        position: absolute;
        width: 40px;
        height: 136px;
        right: 20px;
        bottom: 84px;
        background: rgba(252, 252, 253, 0.6);
        border: border($color: $white);
        border-radius: $br-md;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
        backdrop-filter: blur(6.5px);

        .toolbutton {
            border: none;
            background-color: transparent;
            outline: none;
            height: 24px;
            width: 40px;
            padding: 0;

            img {
                height: 24px;
                width: 24px;
            }
        }
    }
}
