@use "../../scss/abstracts" as *;
@use "../../scss/layout/spacing" as *;
@use "../../scss/theme/colors" as *;

%page-item-container {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    .page-item {
        background: $white;
        @extend %page-item-container;

        &:nth-of-type(2) {
            border-top-left-radius: $br-sm;
            border-bottom-left-radius: $br-sm;
        }

        &:nth-last-of-type(2) {
            border-top-right-radius: $br-sm;
            border-bottom-right-radius: $br-sm;
        }

        + .page-item {
            border-left: border();
        }

        a {
            padding: rem(10);
        }

        &-active {
            background-color: $primary;
        }

        &__link {
            font-weight: $fw-semi-bold;
            color: $body-color;

            &-active {
                color: $white;
            }
        }
    }

    .navigation-button {
        @extend %page-item-container;
        &__link {
            &.prev {
                transform: rotate(180deg);
            }

            &-disabled {
                display: none;
            }
        }
    }
}
