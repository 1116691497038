@use '../../../scss/abstracts' as *;
@use '../../../scss/layout/spacing' as *;
@use '../../../scss/theme/colors' as *;

.payouts {
	.payout-row {
		.btn-toggle {
			width: 100%;
			.vstack {
				gap: $gap-sm;
				align-items: flex-start;

				&:not(:first-of-type) {
					flex-basis: rem(200);
				}

				.event {
					width: 13ch;
				}

				& + .vstack {
					border-left: border();
					padding: 0px $text-spacer;
				}
			}
		}
		#payout-desc {
			@extend .seperator;

			ul {
				justify-content: space-between;
			}

			.main-list {
				+ .main-list {
					border-left: border();
					padding-left: $text-spacer;
				}
				ul {
					font-size: $small;
				}
			}
		}
	}
}
