@use '../../../scss/layout/spacing' as *;
@use '../../../scss/layout/images' as *;

.configuration-card {
	flex-basis: 278px;

	.create-card-body {
		align-items: center;
		&::before {
			@extend .img--square;
			margin-bottom: $text-spacer-sm;
		}

		&--new {
			&::before {
				background-image: url('../../../assets/icons/add.svg');
				background-color: rgba(#f7e53e, var(--opac));
			}
		}

		&--existing {
			&::before {
				background-image: url('../../../assets/icons/clipboard-filled.svg');
				background-color: rgba(#8ff73e, var(--opac));
			}
		}

		&--reset {
			&::before {
				background-image: url('../../../assets/icons/reset.svg');
				background-color: rgba(#f7e53e, var(--opac));
			}
		}
	}
}
