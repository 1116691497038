@use "../../scss/abstracts" as *;
@use "../../scss/layout" as *;
@use "../../scss/theme/buttons" as *;
@use "../../scss/theme/borders" as *;
@use "../../scss/theme/dropdown" as *;

.navigation {
    background-color: $clr-white;

    &.position-sticky {
        top: 0;
        z-index: 999;
    }

    &::after {
        @include full-width-border();

        top: $navbar-height;
    }

    .navbar {
        padding-top: rem(21);
        padding-bottom: rem(21);
        height: $navbar-height;

        .container {
            max-width: 100%;
        }

        &-brand {
            padding-top: rem(3);
            border-right: border();
            padding-right: rem(47);
            margin-right: $text-spacer;
        }

        &-nav {
            padding-top: em(73);
            padding-bottom: em(60);

            #main {
                li + li {
                    padding-top: 0px;
                    padding-left: $text-spacer-xs;
                }
            }
        }
    }

    .nav-link.btn,
    .btn.dropdown-toggle {
        margin-top: 0px;
    }

    .nav-link.btn {
        padding: rem(6) rem(16) !important;
        font-family: $font-body;
        font-weight: $fw-medium;
        border-radius: $border-radius-sm !important;

        &:not(:hover) {
            background: $clr-primary;
        }
    }

    #profile-dropdown {
        background-color: $clr-primary-dark;
        color: #fff;
        border-radius: 50%;
        padding-right: rem(23);
        font-weight: $fw-normal;
        font-size: $body-2;
        width: rem(44);
        height: rem(44);
        padding: 0px;
        border: none;

        &:focus {
            outline: 8px solid rgba($clr-primary-dark, 0.2);
        }
    }

    .dropdown-menu {
        // padding bottom of nav - 21px
        transform: translateY(19px);
        li {
            .dropdown-item {
                @extend %icon-text-box;
                padding: rem(12);
            }
            &:first-of-type {
                .dropdown-item {
                    &::before {
                        content: url($data-svg-prefix + map-get($icons, "settings"));
                    }
                }
            }

            &:last-of-type {
                .dropdown-item {
                    &::before {
                        content: url($data-svg-prefix + map-get($icons, "logout"));
                    }
                }
            }
        }
    }
}
