@use "../abstracts" as *;

.tooltip {
	&-inner {
		font-weight: $fw-medium;
	}
	&-lg {
		.tooltip-inner {
			border-radius: $br-md;
			padding: rem(12);
		}
	}
}
