@use '../../scss/abstracts' as *;

.success {
	.modal-body-heading-title,
	.heading {
		text-align: center;
	}

	&::before {
		content: '';
		background-repeat: no-repeat;
		background-position: center;
		background-image: url('../../assets/icons/success.svg');
		width: 106px;
		height: 106px;
	}
}
