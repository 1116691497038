@use "../abstracts" as *;
@use "./spacing" as *;
@use "./buttons" as *;
@use "./images" as *;
@use "./container" as *;
@use "../theme/colors" as *;
@use "../theme/table" as *;
@use "../theme/borders" as *;

.loading-text {
    margin-top: rem(15);
    font-size: $body-2;
    margin-bottom: 0px;

    &-caption {
        font-size: $caption;
        color: $text-muted;
        margin-top: rem(4);
    }
}
@mixin heading-psuedo($color: $primary) {
    h1::before {
        content: "";
        width: 16px;
        margin-right: $text-spacer-xs;
        background-color: $color;
        border-radius: $br-xs;
    }
}

@mixin heading-psuedo-sm($color: $primary) {
    &::before {
        content: "";
        display: inline-block;
        width: 4px;
        height: 100%;
        margin-right: rem(8);
        background-color: $color;
        border-radius: $br-xs;
    }
}

pre {
    white-space: break-spaces;
    font-family: $font-body;
}

h1,
.h1 {
    font-size: $h1-font-size;
}
h2,
.h2 {
    font-size: $h2-font-size;
}
h3,
.h3 {
    font-size: $h3-font-size;
}
h4,
.h4 {
    font-size: $h4-font-size;
}

.hero,
.headline-1,
.headline-2,
.headline-4 {
    font-weight: $fw-semi-bold;
}

.hero,
.headline-1 {
    @extend .h1 !optional;
}

.headline {
    &-2 {
        @extend .h2 !optional;
    }
    &-3 {
        @extend .h3 !optional;
    }
    // section headers and modal heading titles
    &-4 {
        @extend .h4 !optional;
    }
}

// utility classes for type classes
%bold {
    font-weight: $fw-semi-bold;
}

%medium {
    font-weight: $fw-medium;
}

// 16px - body 2
.normal {
    font-size: $body-2;

    &-bold {
        @extend %bold !optional;
    }
}

// 24px -body 1
.fs-md {
    font-size: $body-1;

    &-bold {
        @extend %bold !optional;
    }
}

// 14px
.small {
    font-size: $small;

    &-bold {
        @extend %medium !optional;
    }
}

// 12px
.caption {
    font-size: $caption;

    &-bold {
        @extend %bold !optional;
    }

    &--light {
        color: $gray-200;
    }
}

.fw-medium {
    font-weight: $fw-medium;
}

.fw-semi-bold {
    font-weight: $fw-semi-bold;
}

// add button, pricing levels, select unassigned button text in seat map sidebar
// text link color
.text-link {
    @extend .btn-link !optional;

    &--thin {
        @extend .btn-link--thin !optional;
    }
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.section-header {
    margin-bottom: $heading-spacer;

    &-sm {
        margin-bottom: $heading-spacer-sm;
    }

    &-desc {
        color: $text-muted;
        font-weight: $fw-medium;
        padding-left: calc(rem(16) + rem(16));
    }
}

.section-heading {
    @include heading-psuedo();

    h1,
    h2,
    h3,
    h4 {
        margin: 0;
        @extend .headline-4 !optional;
        display: flex;
    }

    &--secondary {
        @include heading-psuedo($success);
    }
}

// payment labels
.total,
.paid,
.due,
.overdue {
    display: flex;

    + span {
        padding-left: calc(4px + 8px);
    }
}

.total {
    @include heading-psuedo-sm();
}

.paid {
    @include heading-psuedo-sm($success);
}

.due {
    @include heading-psuedo-sm($secondary);
}

.overdue {
    @include heading-psuedo-sm($danger);
}

// availability period labels -> create offer
.small-label {
    @extend .small !optional;
    @extend .small-bold !optional;
}

.small-label--flex {
    margin-bottom: rem(4);
    gap: rem(8);
}

.subtitle {
    display: block;
    color: $text-muted;
    font-weight: $fw-normal;
    font-size: $small;
    margin-top: rem(7);

    &--dark {
        color: $body-color;
    }
}

// hairline 1
.text-upper {
    text-transform: uppercase;
    font-weight: $fw-bold;
    font-family: $font-body;
    font-size: $body-2;

    // hairline 2
    &-caption {
        font-size: $caption;
    }
}

.nav-link,
.card .nav-link {
    font-family: $font-heading;
}

// color text for alerts, badges and warning cards, event status button -> event banner
.text {
    // black text
    &-dark {
        color: $body-color !important;
    }
    // error text
    &-danger {
        color: $danger !important;
    }
    // green text
    &-success {
        color: $success !important;
    }
    // gray text
    &-muted {
        color: $muted !important;
    }
    // purple text
    &-secondary {
        color: $secondary !important;
    }
    // blue text -> create offer warning cards
    &-primary-dark {
        color: $clr-primary-dark !important;
    }
}

// backgrounds for warning cards and alerts and availability icons -> edit offer and badges -> offers
// event status button -> event banner
// payment statuses
.background {
    $opac: 0.12;

    // error messages, cancelled, overdue badge
    &-danger {
        background-color: rgba($danger, $opac) !important;
        @extend .text-danger !optional;

        &::before,
        &::after {
            filter: $danger-filter;
        }
    }

    // success messages, alerts and on sale, paid badge
    &-success {
        @extend .text-success !optional;
        background-color: rgba($success, $opac) !important;

        &::before,
        &::after {
            filter: $success-filter;
        }
    }

    // draft badge
    &-muted {
        @extend .text-muted !optional;
        background-color: rgba(#141416, $opac) !important;
    }

    // create offer warning cards, published, payment plan badge
    &-primary {
        background-color: rgba($clr-primary, $opac) !important;
        @extend .text-primary-dark !optional;

        &::before,
        &::after {
            filter: $primary-dark-filter;
        }
    }

    // scheduled, pending badge
    &-secondary {
        background-color: rgba($clr-secondary, $opac) !important;
        @extend .text-secondary !optional;

        &::after {
            filter: $secondary-filter;
        }
    }

    // ended badge
    &-dark {
        background-color: $body-color !important;
        color: $white !important;

        &::after {
            filter: $white-filter;
        }
    }

    // sold out badge
    &-tertiary {
        background-color: $clr-tertiary !important;
        @extend .text-dark !optional;

        &::after {
            filter: $dark-filter;
        }
    }
    // publish modal - event info
    &-pale {
        background-color: #fff3cd !important;
    }
}

// text sizes for alerts and warning cards
.text-normal {
    @extend .normal !optional;
}

.text-small {
    font-size: $small;
}

// warning cards and alerts
.info-card {
    @extend .small-bold !optional;
    border: none;

    .info-text {
        align-items: start;
    }
}

// alerts and warning cards and availability info text -> edit offer icons
%icon {
    // default icon
    &::before {
        content: url($data-svg-prefix + map-get($icons, "info"));
    }

    &-info-fill {
        &::before {
            content: url($data-svg-prefix + map-get($icons, "info-filled"));
        }
    }

    // error messages -> alerts
    // required fields
    &-danger {
        &::before {
            content: url($data-svg-prefix + map-get($icons, "warning"));
        }
    }

    // success messages -> alerts
    &-success {
        &::after {
            content: url($data-svg-prefix + map-get($icons, "success"));
        }
    }

    // draft badge
    &-edit {
        &::after {
            content: url($data-svg-prefix + map-get($icons, "edit"));
        }
    }

    // on sale badge
    &-dot {
        &::after {
            content: "";
            background: $success;
            @extend %dot !optional;
            width: rem(12) !important;
            height: rem(12) !important;
        }
    }

    // scheduled badge
    &-calendar {
        &::after {
            content: url($data-svg-prefix + map-get($icons, "calendar"));
        }
    }

    // ended badge
    &-clock {
        &::after {
            content: url($data-svg-prefix + map-get($icons, "clock"));
        }
    }

    // soldout badge
    &-tag {
        &::after {
            content: url($data-svg-prefix + map-get($icons, "tag"));
        }
    }

    // cancelled badge
    &-close {
        &::after {
            content: url($data-svg-prefix + map-get($icons, "close"));
        }
    }

    // event and offer badges -> offers
    &-sm {
        &::after {
            @extend %icon-size-sm !optional; // 16
        }
    }

    &-md {
        &::before {
            @extend %icon-size-md !optional; // 24
        }
    }

    &-lg {
        &::before {
            @extend %icon-size-lg !optional; // 32
        }
    }
}

// event date and time -> event details, offer, emptyContainer text, warning card, alerts
.info-text {
    @extend %icon-text-box !optional;
    position: relative;
    line-height: 1.7;
    gap: $gap-sm;
    @extend .text-small !optional;
    @extend %icon !optional;

    &::before {
        line-height: 0;
        flex-shrink: 0;
    }

    &-fill {
        @extend %icon-info-fill !optional;
    }

    // availability status -> edit offer
    &-onsale {
        @extend %badge-icons !optional;
        @extend %icon-dot !optional;

        &::before {
            @extend .background-success !optional;
        }
    }

    // availability status -> edit offer
    &-scheduled {
        @extend %badge-icons !optional;
        @extend %icon-calendar !optional;

        &::before {
            @extend .background-secondary !optional;
        }

        &::after {
            filter: $secondary-filter;
        }
    }

    // availability status -> edit offer
    &-ended {
        @extend %badge-icons !optional;
        @extend %icon-clock !optional;

        &::before {
            @extend .background-dark !optional;
        }

        &::after {
            filter: $white-filter;
        }
    }

    // availability status -> edit offer
    &-soldout {
        @extend %badge-icons !optional;
        @extend %icon-tag !optional;

        &::before {
            @extend .background-tertiary !optional;
        }

        &::after {
            filter: $dark-filter;
        }
    }

    // offer warning
    &-danger {
        @extend .text-danger !optional;

        &::before {
            filter: $danger-filter;
        }
    }

    // warning cards -> create offer
    &-primary {
        @extend .text-primary-dark !optional;

        &::before {
            filter: $primary-dark-filter;
        }
    }

    // warning cards -> publish modal - event info
    &-pale {
        @extend .text-dark !optional;

        &::before {
            filter: $dark-filter;
        }
    }

    //  availability periods -> edit offer
    &-sm {
        @extend %icon-sm !optional;
    }

    // warning card icons, emptyContainer icons, alerts
    &-md {
        @extend %icon-md !optional;
    }

    // emyptyContainer large icons, daily ticket count warning card, publish modal warning card
    &-lg {
        @extend %icon-lg !optional;

        &.info-text {
            @extend .text-normal !optional;
        }
    }
}

// price modal -> seatmap -> create event flow
.icon-text-box {
    &.increase {
        &::after {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M16.9732 13.516C17.525 13.494 17.9545 13.0287 17.9324 12.4769L17.7421 7.71753C17.7212 7.19663 17.3037 6.77913 16.7828 6.75829L12.0234 6.56789C11.4716 6.54581 11.0063 6.97527 10.9843 7.52712C10.9622 8.07896 11.3916 8.54421 11.9435 8.56629L14.4207 8.6654L7.55046 15.5357C7.15994 15.9262 7.15994 16.5594 7.55046 16.9499C7.94099 17.3404 8.57415 17.3404 8.96468 16.9499L15.8349 10.0796L15.934 12.5568C15.9561 13.1086 16.4213 13.5381 16.9732 13.516Z' fill='%23777E91' /%3E%3C/svg%3E");
            filter: $success-filter;
        }
    }
    &.decrease {
        &::after {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M16.9732 13.516C17.525 13.494 17.9545 13.0287 17.9324 12.4769L17.7421 7.71753C17.7212 7.19663 17.3037 6.77913 16.7828 6.75829L12.0234 6.56789C11.4716 6.54581 11.0063 6.97527 10.9843 7.52712C10.9622 8.07896 11.3916 8.54421 11.9435 8.56629L14.4207 8.6654L7.55046 15.5357C7.15994 15.9262 7.15994 16.5594 7.55046 16.9499C7.94099 17.3404 8.57415 17.3404 8.96468 16.9499L15.8349 10.0796L15.934 12.5568C15.9561 13.1086 16.4213 13.5381 16.9732 13.516Z' fill='%23777E91' /%3E%3C/svg%3E");
            filter: $danger-filter;
            transform: rotate(180deg);
        }
    }
}

.time,
.venue,
.loc,
.to,
.start {
    &::before {
        margin: 0px rem(4);
    }
}
// bulleted for contact attendees - preview modal and event details - general onsale modal, event banner, offer dates, publish event - venue address, assign fan to package popup, fan profile
.time,
.venue,
.loc,
.start {
    &::before {
        content: "\2022";
    }
}

// end dates (when showing start and end dates) -> general onsale, offer date, availability periods -> create offer
// price - high and low -> seat map select map modal, publish modal, offer -> offers page
// banner -> package banner
.to {
    &::before {
        content: "\2192";
    }
}

%text-truncate {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.banner-wrapper {
    .event-details {
        min-width: 0px;

        .event-title {
            @extend %text-truncate !optional;
        }
    }
}

// payout headings, check in attendees headings
.heading {
    @extend .normal !optional;
    @extend .normal-bold !optional;
    margin-bottom: rem(10);

    // roles, team members
    // event info -> publish modal
    &-sm {
        @extend .small !optional;
        @extend .small-bold !optional;
        margin-bottom: rem(3);
    }
}

// ticket breakdown modal -> create offer
// roles, team members, offers, select map modal items -> seat map
.list-item {
    .heading {
        @extend .heading !optional;

        // roles, team members
        // event info -> publish modal
        &-sm {
            @extend .heading-sm !optional;
        }
    }

    // select map modal items -> seat map
    .form-check-display {
        .form-check-label {
            font-weight: $fw-normal;
            font-size: $small;
        }
    }
}

.horizontal-list {
    li {
        color: $text-muted;

        + li {
            margin-left: $text-spacer-xs;
        }

        span {
            margin-left: rem(8);
            color: $body-color;
            font-weight: $fw-semi-bold;
        }
    }
}

// li {
// 	.heading {
// 		// font-weight: $fw-semi-bold;
// 		// margin-bottom: rem(3);

// 		&-caption {
// 			font-size: $caption;
// 			color: $clr-neutral-3;
// 			margin-bottom: rem(6);
// 		}
// 	}
// }

// seat map, create offer -> pricing levels, holds/kills
.item-name,
.num-seats {
    font-size: $small;

    // move modal, create offer
    &-lg {
        font-size: $body-2;
    }
}

.item-name {
    // pricing level/hold - default
    font-weight: $fw-semi-bold;

    // move modal, open sub items -> seatmap
    &-thin {
        font-weight: $fw-normal;
    }
}

.list-table {
    font-size: $small;
    position: relative;

    &--with-bg {
        background-color: $white;

        &-dark {
            background-color: $gray-300;
        }
    }

    &--with-border {
        border: border();
    }

    &--with-radius {
        border-radius: $border-radius;

        .flex-row:last-child {
            border-bottom-right-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
        }
    }

    .btn-more-col {
        position: absolute;
        right: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &--with-border {
            border-left: border();
            padding-left: $text-spacer-xs;
            padding-right: $text-spacer-xs;
        }

        // pricing and fees -> create offer
        .form-switch {
            padding-left: 0px;

            .form-check-input {
                width: rem(50);
            }
        }
    }

    .flex-row {
        display: flex;
        flex-wrap: nowrap;
        position: relative;

        &:not(.flex-row--no-borders) + .flex-row {
            border-top: border();
        }

        &.light:not(.list-table-header) {
            background-color: #fff;
        }
    }

    &-col {
        padding: rem(13) 0px;
        text-align: center;
        font-weight: $fw-medium;
        white-space: nowrap;

        &.text-left {
            @extend .text-left !optional;
        }

        &-header {
            &-sm {
                padding: rem(4);

                &:first-of-type,
                &:last-of-type {
                    padding-left: rem(8);
                }
            }
        }

        .vstack,
        .hstack {
            align-items: center;
            justify-content: center;

            .caption {
                margin: 0px;
                color: $text-muted;
            }
        }

        &-header {
            font-weight: $table-th-font-weight;
            color: $text-muted;

            &--dark {
                color: inherit;
            }
        }

        &:not(.text-center) {
            &:first-of-type {
                @extend .text-left !optional;
            }

            // last-of-type looks at element (div) not class
            // only do this is there is more than one list-table-col
            & + &:last-of-type {
                @extend .text-right !optional;
            }
        }
    }

    // assign packaging -> season packaging
    &--with-inside-borders {
        .list-table-col:not(.list-table-col-header) + .list-table-col:not(.list-table-col-header) {
            border-left: border();
        }
    }
}

.total-row {
    @extend .normal !optional;
    @extend .normal-bold !optional;
}

#bank-card,
#tax-card {
    h1 {
        font-size: rem(20);
        font-weight: $fw-bold;
    }
    line-height: 24px;
}
