@use '../abstracts' as *;
@use '../layout/forms' as *;
@use '../theme' as *;

.select {
	.react-select {
		// Prevent layout shift caused by removing bottom-border
		&.react-select__control--menu-is-open {
			margin-bottom: 2px;
		}
		&__control {
			border-width: $input-border-width;
			border-color: $input-border-color;
			border-radius: $input-border-radius;
		}

		&__value-container {
			padding: rem(6) $input-padding-x-sm;
		}

		&__placeholder {
			font-size: $input-font-size;
			font-weight: $input-font-weight;
		}

		&__multi-value {
			@extend .tag !optional;

			&:first-of-type {
				margin-left: 0px;
			}

			&__label {
				@extend .tag-label !optional;
			}

			&__remove {
				@extend .tag-remove-btn !optional;
			}
		}
		.react-select__control {
			min-height: 48px;
			&:hover {
				border-color: $input-border-color;
			}
			&:focus {
				box-shadow: none;
			}
			&.react-select__control--is-focused {
				box-shadow: none !important;
				border-color: $input-focus-border-color;
			}
			&.react-select__control--menu-is-open {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				// Force no focus / hover effects
				box-shadow: none !important;
				border-color: $input-focus-border-color;
				// Hide bottom border
				border-bottom: none;
			}
		}
		.react-select__input-container {
			font-family: $font-body;
			font-weight: $fw-medium;
			color: $clr-neutral-4;
			font-size: $small;
		}
		.react-select__menu {
			margin-top: 0;
			box-shadow: none;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-bottom-left-radius: $input-border-radius;
			border-bottom-right-radius: $input-border-radius;
			border: $input-border-width solid $input-focus-border-color;
			border-top: none;
		}
		.form-check-inline {
			margin-right: 8px;
		}
		.form-check-input {
			border-width: 2px;
		}
		.form-check-input:not(:checked) {
			border-color: $input-border-color;
		}
	}
	.selected-count {
		margin-top: 4px;
		width: calc(100% - 24px);
		margin-left: auto;
		margin-right: auto;
		padding-top: 16px;
		color: $clr-neutral-3;
		font-weight: 400;
		font-size: 12px;
		line-height: 20px;
		font-family: $font-body;
		border-top: border();
	}
	.select-label {
		font-family: $font-body;
		font-size: $small;
		margin-left: auto;
		display: flex;
		align-items: center;
		&.one {
			color: $clr-neutral-2;
			font-weight: $fw-medium;
		}
		&.two {
			color: $clr-neutral-4;
			font-weight: $fw-normal;
		}
	}
}
