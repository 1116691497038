@use '../../../../scss/abstracts' as *;

.card-thumbnail {
	.badge {
		position: absolute;
		bottom: rem(4);
		left: rem(4);
	}

	.card-img-overlay {
		transition: 0.5s ease;
		background: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0px;
		opacity: 0;
	}

	&:hover,
	&:focus {
		.card-img-overlay {
			opacity: 1;
		}
	}
}
